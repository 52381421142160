<template>
  <div class="header">
    <router-link to="/venues" class="logo">
      <svg
        width="83"
        height="32"
        viewBox="0 0 83 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M65.287 32V7.08923H56.8193V0H82.2224V7.08923H73.7547V32H65.287Z"
          fill="#00246B"
        />
        <path
          d="M26.1055 32V0H34.7701L41.8593 14.2769L44.9116 21.4646H45.1086C44.9116 19.7251 44.6819 17.7559 44.4193 15.5569C44.1568 13.3251 44.0255 11.2246 44.0255 9.25538V0H52.0993V32H43.4347L36.3455 17.6738L33.2932 10.5354H33.0962C33.2932 12.3405 33.5229 14.3426 33.7855 16.5415C34.048 18.7077 34.1793 20.7754 34.1793 22.7446V32H26.1055Z"
          fill="#00246B"
        />
        <path d="M0 32V0H8.46769V24.9108H20.5785V32H0Z" fill="#00246B" />
      </svg>
    </router-link>
    <div class="right_box">
      <router-link
        to="/events"
        :class="{ 'active-link': ['events'].includes(this.$route.name) }"
        class="menu_item"
        >Events</router-link
      >
      <router-link
        to="/communities"
        :class="{ 'active-link': ['communities'].includes(this.$route.name) }"
        class="menu_item"
        >Communities</router-link
      >
      <router-link
        to="/venues"
        :class="{ 'active-link': ['venues'].includes(this.$route.name) }"
        class="menu_item"
        >Venues</router-link
      >
      <router-link
        to="/deals"
        :class="{ 'active-link': ['deals'].includes(this.$route.name) }"
        class="menu_item"
        >Deals</router-link
      >
      <router-link
        to="/users"
        :class="{ 'active-link': ['users'].includes(this.$route.name) }"
        class="menu_item"
        >Users</router-link
      >
      <router-link
        to="/reports"
        :class="{ 'active-link': ['reports'].includes(this.$route.name) }"
        class="menu_item"
        >Reports</router-link
      >
      <router-link
        to="/plans"
        :class="{ 'active-link': ['plans'].includes(this.$route.name) }"
        class="menu_item"
        >Plans</router-link
      >
      <router-link
        to="/promo-codes"
        :class="{ 'active-link': ['promo-codes'].includes(this.$route.name) }"
        class="menu_item"
        >Promo codes</router-link
      >
      <div>
        <svg
          @click="logout"
          class="cp"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3 2C2.73478 2 2.48043 2.10536 2.29289 2.29289C2.10536 2.48043 2 2.73478 2 3V17C2 17.2652 2.10536 17.5196 2.29289 17.7071C2.48043 17.8946 2.73478 18 3 18H7C7.55228 18 8 18.4477 8 19C8 19.5523 7.55228 20 7 20H3C2.20435 20 1.44129 19.6839 0.87868 19.1213C0.31607 18.5587 0 17.7957 0 17V3C0 2.20435 0.31607 1.44129 0.87868 0.87868C1.44129 0.31607 2.20435 0 3 0H7C7.55228 0 8 0.447715 8 1C8 1.55228 7.55228 2 7 2H3ZM13.2929 4.29289C13.6834 3.90237 14.3166 3.90237 14.7071 4.29289L19.7071 9.29289C20.0976 9.68342 20.0976 10.3166 19.7071 10.7071L14.7071 15.7071C14.3166 16.0976 13.6834 16.0976 13.2929 15.7071C12.9024 15.3166 12.9024 14.6834 13.2929 14.2929L16.5858 11H7C6.44772 11 6 10.5523 6 10C6 9.44771 6.44772 9 7 9H16.5858L13.2929 5.70711C12.9024 5.31658 12.9024 4.68342 13.2929 4.29289Z"
            fill="#0075FF"
          />
        </svg>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Header',
  methods: {
    async logout() {
      delete localStorage.lnt_user;
      delete localStorage.lnt_jwt;
      await this.$router.push('/login');
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 62px;
  padding: 0px 30px;
}

.right_box {
  display: flex;
  gap: 20px;
  align-items: center;
}
.menu_item {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #0075ff;
  cursor: pointer;
}
.active-link {
  color: #acbad7;
}
</style>
