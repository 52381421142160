import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/LoginView.vue'),
  },
  {
    path: '/communities',
    name: 'communities',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "venues" */ '../views/CommunitiesView.vue'),
  },
  {
    path: '/community/:id',
    name: 'communityDetails',
    meta: {
      requiresAuth: true,
    },
    component: () => import('../views/CommunityDetails.vue'),
  },
  {
    path: '/edit/community/:id',
    name: 'editCommunity',
    meta: {
      requiresAuth: true,
    },
    component: () => import('../views/EditCommunitiesView.vue'),
  },
  {
    path: '/venues',
    name: 'venues',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "venues" */ '../views/VenuesView.vue'),
  },
  {
    path: '/venue/:id',
    name: 'venueDetails',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "venueDetails" */ '../views/VenueDetails.vue'
      ),
  },
  {
    path: '/edit/venue/:id',
    name: 'editVenue',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "editVenue" */ '../views/EditVenue.vue'),
  },
  {
    path: '/deals',
    name: 'deals',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "deals" */ '../views/DealsView.vue'),
  },
  {
    path: '/deal/:id',
    name: 'dealDetails',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "dealDetails" */ '../views/DealDetails.vue'),
  },
  {
    path: '/plan/:id',
    name: 'planDetails',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "planDetails" */ '../views/PlanDetails.vue'),
  },
  {
    path: '/edit/plan/:id',
    name: 'editPlan',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "editPlan" */ '../views/EditPlan.vue'),
  },
  {
    path: '/edit/deal/:id',
    name: 'editDeal',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "editDeal" */ '../views/EditDeals.vue'),
  },
  {
    path: '/users',
    name: 'users',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "users" */ '../views/UsersView.vue'),
  },
  {
    path: '/user/:id',
    name: 'userDetails',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "userDetails" */ '../views/UserDetails.vue'),
  },
  {
    path: '/reports',
    name: 'reports',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "reports" */ '../views/ReportsView.vue'),
  },
  {
    path: '/events',
    name: 'events',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "events" */ '../views/EventsView.vue'),
  },
  {
    path: '/plans',
    name: 'plans',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "plans" */ '../views/PlansView.vue'),
  },
  {
    path: '/add-plans',
    name: 'addPlans',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "addPlans" */ '../views/AddPlansView.vue'),
  },
  {
    path: '/add-venues',
    name: 'addVenues',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "addVenues" */ '../views/AddVenuesView.vue'),
  },
  {
    path: '/add-deals',
    name: 'addDeals',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "addDeals" */ '../views/AddDealsView.vue'),
  },
  {
    path: '/add-communities',
    name: 'addCommunities',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "addDeals" */ '../views/AddCommunitiesView.vue'
      ),
  },
  {
    path: '/set-forgot-password',
    name: 'setForgotPassword',
    component: () =>
      import(
        /* webpackChunkName: "setForgotPassword" */ '../views/SetForgotPassword.vue'
      ),
  },
  {
    path: '/promo-codes',
    name: 'promoCodes',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "promoCodes" */ '../views/PromoCodesView.vue'
      ),
  },
  {
    path: '/promo-code/:id',
    name: 'promoCodeDetails',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "promoCodeDetails" */ '../views/PromoCodeDetails.vue'
      ),
  },
  {
    path: '/edit/promo-code/:id',
    name: 'editPromoCode',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "editPromoCode" */ '../views/PromoCodeEdit.vue'
      ),
  },
  {
    path: '/promo-code/create',
    name: 'promoCodeCreate',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "promoCodeCreate" */ '../views/CreatePromoCode.vue'
      ),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: 'active-link',
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const user = JSON.parse(localStorage.getItem('lnt_user'));
  const jwt = localStorage.getItem('lnt_jwt');

  if (!requiresAuth) {
    next();
    return;
  }

  if (!user || !jwt) {
    next('/login');
    // next({ path: '/login', query: { nextUrl: to.fullPath } });
  }
  next();
});
export default router;
